import request from "@/utils/request";
/**
 * 创建广告
 * @param params
 * @returns {*}
 */
export function createAd(params) {
  return request({
    url: "/api/backend/setting/appletad/createAd",
    method: "post",
    data: params
  });
}

/**
 * 查询广告
 * @param params
 * @returns {*}
 */
export function getAdList(params) {
  return request({
    url: "/api/backend/setting/appletad/getList",
    method: "get",
    params
  });
}

/**
 * 修改状态
 * @param params
 * @returns {AxiosPromise}
 */
export function updateStatusAd(params) {
  return request({
    url: "/api/backend/setting/appletad/updateStatus",
    method: "post",
    data: params
  });
}
